<template>
  <div class="fileDownAndPreView flex">
    <a-tooltip v-if="!isMobile()">
      <a-spin :spinning="spinning">
        <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
        <template slot="title"> 下载 </template>
        <a-button type="link" icon="download" @click="download(file)" />
      </a-spin>
    </a-tooltip>
    <a-tooltip v-if="fileType.some((j) => file.fileName.endsWith(j))">
      <template slot="title"> 预览 </template>
      <a-button type="link" icon="eye" @click="preView(file)" />
    </a-tooltip>
  </div>
</template>
<script>
import { downLoadAndPre } from '@/mixins/index'
import { mixinDevice } from '@/utils/mixin'
export default {
  mixins: [downLoadAndPre, mixinDevice],
  props: {
    file: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      fileType: ['.doc', '.docx', '.pdf', '.xls', 'xlsx', '.png', '.jpg'],
      spinning: false,
    }
  },
}
</script>
<style lang="less" scoped>
.fileDownAndPreView {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
</style>
