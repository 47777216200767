<template>
  <tbody class="tbody always">
    <tr v-for="(o, i) in copyList" :key="i" class="bigtt align_l">
      <th>{{ o.name }}</th>
      <td colspan="3">
        <template v-for="(node, index) in o.nodes">
          <div class="flex resultList width100 bigtt" :key="node.id">
            <a-row class="height100 width100 flex resultList_item flex-jc-sb">
              <a-col :span="24">
                <div class="opinion">{{ node.opinion }}</div>
              </a-col>
              <a-col :span="24" class="userName_gap flex-jc-fe">
                <div>
                  <p class="text-a-c color1">
                    <OpenData type="userName" :openid="node.userId" :corpid="node.corpId"></OpenData>
                  </p>
                  <p class="color1" v-if="node.approvalTime">
                    {{ formatApprovalTime(node.approvalTime) }}
                  </p>
                </div>
              </a-col>
            </a-row>
          </div>
          <div
            v-if="o.nodes.length > 1 && index != o.nodes.length - 1"
            style="margin: 0; border-bottom: 1px dashed #ccc"
            class="line width100"
          ></div>
        </template>
      </td>
    </tr>
  </tbody>
</template>
<script>
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import moment from 'moment'
export default {
  components: {
    OpenData,
  },
  data() {
    return {
      moment,
    }
  },
  props: {
    list: {
      type: Array,
      default() {
        return []
      },
    },
  },
  computed: {
    copyList() {
      return Array.isArray(this.list) ? this.list : []
    },
  },
  methods: {
    formatApprovalTime(approvalTime) {
      if (!approvalTime) return ''
      approvalTime = approvalTime.replace(/-/g, '/')
      const date = new Date(approvalTime)
      const dateStr = date.toLocaleString()
      return moment(approvalTime).format('YYYY-MM-DD HH:mm:ss')
      return dateStr.replace(/\//g, '-')
    },
  },
}
</script>
<style lang="less" scoped>
.bigtt {
  height: 150px;
}
.align_l {
  td {
    text-align: left;
    padding: 15px;
    vertical-align: text-top;
    white-space: pre-line;
  }
}
.tbody {
  ::v-deep .ant-row::before,
  ::v-deep .ant-row::after {
    content: none;
  }
  th,
  td {
    text-align: center;
    border: 1px solid black;
    // padding-left: 11px;
    &.textLeft {
      text-align: left;
    }
  }
  td {
    padding: 0;
  }
}

.resultList {
  justify-content: flex-start;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  margin-bottom: 10px;
  justify-content: flex-start;
  // padding: 0 15px;
  font-size: 14px;
  padding: 5px 15px;
  .resultList_item {
    flex-direction: column;
    overflow-y: scroll;
    justify-content: space-between;
    .color1 {
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .resultList_item::-webkit-scrollbar {
    display: none;
  }
  .resultList_item .gap {
    margin: 0 0 0 2px;
  }
  .line {
    border-bottom: 1px dashed #ccc;
  }
  .userName_gap {
    // margin-top: 15px;
    display: flex;
    p {
      margin: 0;
    }
  }
  .opinion {
    margin: 0 0 15px;
    text-align: left;
  }
}
</style>